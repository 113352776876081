import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as IconBack } from '@/assets/svg/icon-back.svg';

import Link from '../Link';

import BreadcrumbsItem from './components/BreadcrumbsItem';

type Props = {
  breadcrumbs: Array<{
    id: number;
    label: string;
    to: React.ComponentProps<typeof Link>['to'];
  }>;
  isWhite?: boolean;
};

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

function Breadcrumbs({ breadcrumbs, isWhite }: Props) {
  const size = useWindowSize();
  const router = useRouter();

  const getParent = (): any => {
    if (breadcrumbs.length === 0) return '/';
    if (breadcrumbs.length === 1) return breadcrumbs[0].to;
    return breadcrumbs[breadcrumbs.length - 2].to;
  };

  return (
    <Component>
      <Inner>
        {size.width <= 768 && breadcrumbs.length > 1 ? (
          <Back
            onClick={() => {
              router.push(getParent());
            }}
          >
            <IconBack />
            <BackTitle>Назад</BackTitle>
          </Back>
        ) : (
          <BreadcrumbList
            itemScope
            itemType="https://schema.org/BreadcrumbList"
          >
            {breadcrumbs.map((breadcrumbsItem, index) => (
              <BreadcrumbsCell key={index} isWhite={isWhite}>
                <BreadcrumbsItem
                  breadcrumbsItem={breadcrumbsItem}
                  index={index}
                  isWhite={isWhite}
                  isLast={
                    breadcrumbs[breadcrumbs.length - 1].id ===
                    breadcrumbsItem.id
                  }
                />
              </BreadcrumbsCell>
            ))}
          </BreadcrumbList>
        )}
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  padding-top: 60px;

  ${media.laptop(css`
    padding-top: 66px;
  `)}

  ${media.mobile(css`
    padding-top: 52px;
    z-index: 2;
    position: relative;
  `)}
`;

const Inner = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const BackTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #020202;
  margin-left: 8px;
`;

const BreadcrumbList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Back = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const BreadcrumbsCell = styled.div<{ isWhite?: boolean }>`
  margin-right: 17px;
  transition: all 0.3s linear;
  color: ${colors.black};

  ${media.mobile(css`
    margin-right: 12px;
  `)}
  ${(props) =>
    props.isWhite
      ? css`
          color: ${colors.white};
        `
      : ''}
  &:hover {
    color: ${colors.orange};
  }

  &:last-child {
    margin-right: 0;
    color: ${colors.orange};
    pointer-events: none;

    svg {
      display: none;
    }
  }
`;
export default Breadcrumbs;
