import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Breadcrumbs from '@/components/Breadcrumbs';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadcrumbsItemType } from '@/typings/model';
import { getBreadcrumbsList } from '@/utils/common';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';

function DefaultTemplate() {
  const page = useCurrentPage();
  const { setThemePage, setActiveStoreName, setMenuStore } = useLayout();

  const headerMenuFashionMoll = useTypedSelector(
    (state) => selectMenuItemListByAlias(state, 'fashion-moll') ?? []
  );

  const breadcrumbList: Array<BreadcrumbsItemType> = getBreadcrumbsList(
    page?.title ?? '',
    page?.parent ?? undefined
  );

  useEffect(() => {
    if (page?.path && page?.path.includes('modnyy-moll')) {
      setThemePage('#E4FF41');
      setActiveStoreName({
        value: 'Модный молл',
        label: 'Модный молл',
      });
      setMenuStore(headerMenuFashionMoll);
    } else {
      setThemePage('');
    }
    return () => {
      setActiveStoreName({
        value: '',
        label: '',
      });
      setMenuStore([]);
    };
  }, [page?.path, headerMenuFashionMoll]);

  return (
    <Container>
      <Breadcrumbs breadcrumbs={breadcrumbList} />
      <Hero>
        <HeroInner>
          <Title>{page?.title}</Title>
        </HeroInner>
      </Hero>
      <Static>
        <Inner
          dangerouslySetInnerHTML={{
            __html: page?.body ?? '',
          }}
        />
      </Static>
    </Container>
  );
}

export const Container = styled.div`
  overflow: hidden;
`;

export const Hero = styled.div`
  padding-top: 92px;

  ${media.laptop(css`
    padding-top: 64px;
  `)}

  ${media.tablet(css`
    padding-top: 30px;
  `)}
`;

export const HeroInner = styled.div`
  max-width: 1056px;
  margin: 0 auto;

  ${media.laptop(css`
    max-width: 792px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

export const Title = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

export const Static = styled.div`
  max-width: 1056px;
  margin: 0 auto;
  padding: 60px 0 150px;

  ${media.laptop(css`
    max-width: 792px;
    padding: 40px 0 120px;
  `)}

  ${media.tablet(css`
    padding: 30px 20px 120px;
  `)}

  ${media.mobile(css`
    padding: 20px 20px 80px;
  `)}
`;

export const Inner = styled.div`
  h2 {
    margin: 0 0 42px;
    font-weight: 500;
    font-size: 72px;
    line-height: 80px;

    ${media.laptop(css`
      margin-bottom: 32px;
      font-size: 64px;
      line-height: 72px;
    `)}
  }

  h3 {
    font-weight: 500;
    font-size: 50px;
    line-height: 58px;
    margin: 3px 0 34px;

    ${media.laptop(css`
      margin: 0 0 16px;
      font-size: 48px;
      line-height: 56px;
    `)}

    ${media.tablet(css`
      margin: 0 0 16px;
      font-size: 32px;
      line-height: 38px;
    `)}

    ${media.mobile(css`
      margin: 0 0 16px;
      font-size: 28px;
      line-height: 34px;
    `)}
  }

  h4 {
    margin: 84px 0 27px;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;

    ${media.laptop(css`
      margin: 56px 0 16px;
      font-size: 24px;
      line-height: 32px;
    `)}
  }

  p {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 34px;

    ${media.laptop(css`
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 26px;
    `)}

    ${media.tablet(css`
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 20px;
    `)}

    ${media.mobile(css`
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
    `)}
  }

  b,
  strong {
    font-weight: 700;
  }

  a {
    color: ${colors.orange};
    text-decoration: underline;
    text-decoration-color: ${colors.orange};
    transition: all 0.3s linear;

    &:hover {
      text-decoration-color: transparent;
    }
  }

  img {
    margin: 40px 0 20px;
    width: 100%;
    border-radius: 36px;
    overflow: hidden;

    ${media.laptop(css`
      margin: 24px 0 16px;
      border-radius: 24px;
    `)}
    & + figcaption {
      margin-top: 20px;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: ${colors.grayLight};

      ${media.laptop(css`
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
      `)}
    }
  }

  .image-style-side {
    float: left;
    margin: 0 20px 20px 0;

    img {
      margin: 0;
      width: auto;
      border-radius: 0;
    }
  }

  ul,
  ol {
    margin-bottom: 84px;

    ${media.laptop(css`
      margin-bottom: 56px;
    `)}

    ${media.tablet(css`
      margin-bottom: 20px;
    `)}

    ${media.mobile(css`
      margin-bottom: 10px;
    `)}
  }

  ul {
    li {
      position: relative;
      text-indent: 36px;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 20px;

      &:before {
        position: absolute;
        content: '';
        top: 13px;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: ${colors.orange};
      }

      ${media.laptop(css`
        margin-bottom: 0;
        text-indent: 28px;
        font-size: 20px;
        line-height: 26px;

        &:before {
          top: 10px;
          width: 8px;
          height: 8px;
        }
      `)}
      ${media.tablet(css`
        font-size: 18px;
        line-height: 22px;

        &:before {
          top: 7px;
        }
      `)}
      ${media.mobile(css`
        font-size: 14px;
        line-height: 20px;
        text-indent: 18px;

        &:before {
          top: 6px;
        }
      `)}
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    counter-reset: list;

    li {
      position: relative;
      text-indent: 36px;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 20px;

      &:before {
        position: absolute;
        content: '';
        top: 13px;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: ${colors.orange};
      }

      ${media.laptop(css`
        margin-bottom: 0;
        text-indent: 28px;
        font-size: 20px;
        line-height: 26px;

        &:before {
          top: 11px;
          width: 8px;
          height: 8px;
        }
      `)}
      ${media.tablet(css`
        font-size: 18px;
        line-height: 22px;
        text-indent: 18px;

        &:before {
          top: 9px;
        }
      `)}

      ${media.mobile(css`
        font-size: 14px;
        line-height: 20px;
        text-indent: 18px;

        &:before {
          top: 9px;
        }
      `)}
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .table {
    margin: 0 -136px;
    max-width: 1328px;
    overflow-x: auto;

    ${media.laptop(css`
      margin: 0 -102px;
      max-width: 996px;
    `)}

    ${media.tablet(css`
      margin: 0;

      overflow: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        position: absolute;
        top: 0;
        border-radius: 4px;
        background-color: ${colors.orange};
      }
    `)}
  }

  table {
    table-layout: fixed;
    width: 100%;
    max-width: 100vw;
    margin: 56px auto 0;

    ${media.tablet(css`
      margin: 30px auto 0;
    `)}
    ${media.mobile(css`
      margin-top: 20px;
      min-width: 700px;
    `)}
    thead {
      th {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: ${colors.grayLight};
        padding-bottom: 48px;

        ${media.laptop(css`
          font-size: 12px;
          padding-bottom: 40px;
        `)}
        ${media.tablet(css`
          padding-bottom: 20px;
        `)}
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 0;
          ${media.laptop(css`
            font-size: 12px;
          `)}
        }
      }
    }

    tbody {
      tr {
        td {
          position: relative;
          padding: 32px 0;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          color: ${colors.black};
          vertical-align: baseline;

          ${media.tablet(css`
            &:not(:last-child) {
              padding-right: 20px;
            }
          `)}
          ${media.mobile(css`
            &:not(:last-child) {
              padding-right: 5px;
            }
          `)}
          a {
            text-decoration-color: transparent;
            word-wrap: break-word;

            &:hover {
              text-decoration-color: ${colors.orange};
            }
          }
          p {
            font-weight: normal;
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 0;

            ${media.laptop(css`
              font-size: 20px;
              line-height: 26px;
            `)}

            ${media.tablet(css`
              font-size: 18px;
              line-height: 22px;
            `)}
            ${media.mobile(css`
              font-size: 14px;
              line-height: 20px;
            `)}
          }

          ${media.laptop(css`
            padding: 24px 0;
            font-size: 16px;
            line-height: 24px;
          `)}
          ${media.tablet(css`
            font-size: 14px;
            line-height: 22px;
          `)}
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: -3px;
            height: 1px;
            background: ${colors.grayLight};
          }

          &:last-child {
            &:after {
              right: 0;
            }
          }
        }

        &:first-child {
          td {
            padding-top: 0;
          }
        }

        &:last-child {
          td {
            padding-bottom: 0;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export default DefaultTemplate;
