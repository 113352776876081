import React from 'react';

import { isBrowser, Nullable, RequestError } from '@tager/web-core';

import ErrorPage from '@/pages/_error';
import { BreadcrumbsItemType, ParentPageType } from '@/typings/model';
import {
  BreadCrumbs,
  PageBreadCrumbs,
  ParentBreadCrumbs,
} from '@/typings/common';

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';

  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function convertAliasToPath(
  alias: Array<string> | string | undefined
): string {
  if (!alias) return '';
  const convertString = convertSlugToPath(alias);

  return convertString.substring(convertString.indexOf('/') + 1);
}

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if ('status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

export function getParamAsString(
  param: Array<string> | string | undefined
): string {
  return Array.isArray(param) ? param[0] : param ?? '';
}

function createBreadcrumbItem(id: number, title: string, link: string) {
  return { id: id, label: title, to: link };
}

export const getScrollBarWidth = (): number => {
  if (!isBrowser()) {
    return 0;
  }
  return window.innerWidth - document.body.clientWidth;
};

export function getBreadcrumbsList(
  currentPage: string,
  parentPage?: ParentPageType
) {
  const breadcrumbsList: Array<BreadcrumbsItemType> = [
    { id: 1, label: 'Главная', to: '/' },
  ];

  if (parentPage) {
    breadcrumbsList.push(
      createBreadcrumbItem(2, parentPage.title, parentPage.path)
    );
  }
  breadcrumbsList.push(createBreadcrumbItem(3, currentPage, '/'));

  return breadcrumbsList;
}

export const setBreadCrumbsList = (
  page: PageBreadCrumbs,
  parent?: Nullable<Array<ParentBreadCrumbs> | ParentBreadCrumbs>
): Array<BreadCrumbs> => {
  const listWithDefault = [
    {
      id: 1,
      label: 'Главная',
      to: '/',
    },
  ];
  if (!parent) {
    listWithDefault.push({
      id: 2,
      label: page.title,
      to: page.path,
    });
    return listWithDefault;
  }

  if (Array.isArray(parent)) {
    parent.forEach((item, index) => {
      listWithDefault.push({
        id: index + 2,
        label: item.title,
        to: item.path,
      });
      if (index === parent.length - 1) {
        listWithDefault.push({
          id: index + 3,
          label: page.title,
          to: page.path,
        });
      }
    });
    return listWithDefault;
  }

  listWithDefault.push(
    {
      id: 2,
      label: parent.title,
      to: parent.path,
    },
    {
      id: 3,
      label: page.title,
      to: page.path,
    }
  );

  return listWithDefault;
};

export const shouldAddCityPrefix = (city: string) => {
  if (city.startsWith('аг.')) {
    return city;
  }
  return `г. ${city}`;
};

export function formatDate(date: Date): string {
  return date.toLocaleDateString();
}
