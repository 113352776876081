import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { ReactComponent as ArrowRight } from '@/assets/svg/breadcrumbs-arrow.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  breadcrumbsItem: {
    id: number;
    label: string;
    to: React.ComponentProps<typeof Link>['to'];
  };
  index: number;
  isWhite?: boolean;
  isLast?: boolean;
};

function BreadcrumbsItem({
  breadcrumbsItem,
  index,
  isWhite,
  isLast = false,
}: Props) {
  const { to, label } = breadcrumbsItem;

  return (
    <Component
      itemProp="itemListElement"
      itemScope
      itemType="http://schema.org/ListItem"
      isWhite={isWhite}
    >
      {isLast ? (
        <Item>
          <span itemProp="name">{label}</span>
          <ArrowRight />
        </Item>
      ) : (
        <ItemLink to={to} itemProp="item">
          <span itemProp="name">{label}</span>
          <ArrowRight />
        </ItemLink>
      )}
      <meta itemProp="position" content={(index + 1).toString()} />
    </Component>
  );
}

const Component = styled.div<{ isWhite?: boolean }>`
  position: relative;
  padding-right: 25px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  ${media.laptop(css`
    padding-right: 24px;
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 26px;
    padding-right: 18px;
  `)}

  svg {
    position: absolute;
    content: '';
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 7px;
    height: 12px;

    ${media.mobile(css`
      width: 5px;
      height: 10px;
    `)}

    ${(props) =>
      props.isWhite
        ? css`
            color: ${colors.white}!important;
          `
        : css`
            color: ${colors.black}!important;
          `}
  }
`;

const ItemLink = styled(Link)``;
const Item = styled.div``;

export default BreadcrumbsItem;
